import { makeAutoObservable, runInAction } from 'mobx';
import { getClusters, getNamespace, getPodList, getPodLog,
  getSupportLog,
  getServiceId,
  getActionByAction,
  getLogByTraceId,
  getSmo,
  queryVehicleData} from '@/api';

class VehicleSupport {
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }
  supportLog = {}
  smoSummary = {}
  clusterList = [];
  serviceIds = [];
  actions = [];
  traceIdLog = [];
  namespaceList = [];
  podNameList = [];
  logList = [];
  tableLoading = false;
  nameSpaceLoading = false;
  podNameLoading = false;
  current = 1;
  pageSize = 50;
  allLogContentText = '';

  vehicleData = {};

  savePagination(current, pageSize) {
    this.current = current;
    this.pageSize = pageSize;
  }

  async getClusters() {
    let res;
    try {
      res = await getClusters();
      if (res?.code === 200 && res?.data) {
        runInAction(() => {
          this.clusterList = res.data || [];
        });
      }
    } catch (err) { }

    return this.clusterList;
  }


  //获取日志信息
  async getPodLog(values) {
    // console.log("values", values);
    runInAction(() => {
      this.tableLoading = true;
    });

    let res;
    try {
      res = await getPodLog(values);
      if (res?.data) {
        runInAction(() => {
          this.allLogContentText = res.data;
        });
      }
    } catch (err) { }

    runInAction(() => {
      this.tableLoading = false;
    });

    return this.allLogContentText;
  }

  //获取应用列表数据(namespace)
  async getNamespace(cluster) {
    runInAction(() => {
      this.nameSpaceLoading = true;
    });
    let res;
    try {
      res = await getNamespace({ cluster });
      if (res?.data) {
        runInAction(() => {
          this.namespaceList = res.data;
        });
      }
    } catch (err) { }

    runInAction(() => {
      this.nameSpaceLoading = false;
    });

    return this.namespaceList;
  }





  //获取podName列表数据
  async getPodNameList(cluster, namespace) {
    runInAction(() => {
      this.podNameLoading = true;
    });
    let res;
    try {
      res = await getPodList({ cluster, namespace });
      if (res?.data) {
        runInAction(() => {
          this.podNameList = res.data;
        });
      }
    } catch (err) { }

    runInAction(() => {
      this.podNameLoading = false;
    });

    return this.podNameList;
  }

  //获取支持日志
  async getSupportLog(values) {
    let attempts = 0;
    let success = false;
    let res;

    while (attempts < 3 && !success) {
      runInAction(() => {
        this.tableLoading = true;
      });

      try {
        res = await getSupportLog(values);
        if (res?.data) {
          runInAction(() => {
            this.supportLog = res.data;
          });
          if (this.supportLog) {
            success = true;
          }
        }
      } catch (err) {
        console.error('Error fetching support log:', err);
      }

      runInAction(() => {
        this.tableLoading = false;
      });

      attempts++;
    }

    return this.supportLog;
  }


  async getServiceId() {
    let res;
    try {
      res = await getServiceId();
      runInAction(() => {
        this.serviceIds = res.data || [];
      });
    } catch (err) { }

    return this.serviceIds;
  }

  async getActionByAction(serviceId) {
    let attempts = 0;
    let success = false;
    let res;

    while (attempts < 3 && !success) {
      runInAction(() => {
        this.nameSpaceLoading = true;
      });

      try {
        res = await getActionByAction({ serviceId });
        if (res?.data) {
          runInAction(() => {
            this.actions = res.data;
          });
          if (this.actions) {
            success = true;
          }
        }
      } catch (err) {
        console.error('Error fetching actions:', err);
      }

      runInAction(() => {
        this.nameSpaceLoading = false;
      });

      attempts++;
    }

    return this.actions;
  }

  async getLogByTraceId(keyWords) {
    let attempts = 0;
    let success = false;
    let res;

    while (attempts < 3 && !success) {
      try {
        res = await getLogByTraceId(keyWords);
        if (res?.data) {
          runInAction(() => {
            this.traceIdLog = res.data;
          });
          if (this.traceIdLog) {
            success = true;
          }
        }
      } catch (err) {
        console.error('Error fetching log by trace ID:', err);
      }
      attempts++;
    }

    return this.traceIdLog;
  }

  async getSmo(keyWords) {
    let res;
    try {
      res = await getSmo(keyWords);
      if (res?.data) {
        runInAction(() => {
          this.smoSummary = res.data;
        });
      }
    } catch (err) { }
    return this.smoSummary;
  }

  async queryVehicleData(queryBody) {
    let res;
    try {
      res = await queryVehicleData(queryBody);
      if (res?.data) {
        return res.data;
      }
    } catch (err) { }
  }
}
const vehicleSupport = new VehicleSupport();
export default vehicleSupport;
